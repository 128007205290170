import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Editor } from "@tinymce/tinymce-react";
import { useRef, useState } from "react";
import { useDebounce } from "../utils/debounce";

export default function QuickSend() {
  const editorRef = useRef(null);
  const [emails, setEmails] = useState("");

  const emailSign = "{{ .User.Email }}";
  const firstnameSign = "{{ .User.Vorname }}";
  const fullName = "{{ .User.Name }}";
  const lastName = "{{ .User.Nachname }}";

  const { debounce } = useDebounce();

  const handleSend = async () => {
    if (editorRef.current) {
      let sendingMails = emails.replaceAll("\n", ",");

      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + Cookies.get("bdr_teams_authorization")
      );
      myHeaders.append("Content-Type", "application/json");
      const response = await fetch(
        window.extended.TEAMS_BROADCAST_API_URL +
          "/api/send?emails=" +
          sendingMails,
        {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
          body: editorRef.current.getContent(),
        }
      );

      if (response.status === 200) {
        toast.success("Erfolgreich gesendet", { position: "bottom-right" });
      } else if (response.status === 401) {
        toast.error("Authorisierung ist abgelaufen. Melde dich erneut an");
        Cookies.remove("bdr_teams_authorization");
      }
    }
  };

  return (
    <>
      <div className="space-y-12">
        <div className="border-b border-white/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-white">
            Quick Send
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            Hier kannst du schnell Nachrichten verschicken
          </p>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="col-span-full">
              <label
                htmlFor="emails"
                className="block text-sm font-medium leading-6 text-white"
              >
                Emails der Personen an die eine Nachricht geschickt wird, durch{" "}
                <span className="font-bold">Kommata oder Zeilenumbrüche</span>{" "}
                getrennt.
              </label>
              <div className="mt-2">
                <textarea
                  id="emails"
                  name="emails"
                  rows={3}
                  value={emails}
                  onChange={(e) => {
                    setEmails(e.target.value);
                  }}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="col-span-full">
              <Editor
                licenseKey="gpl"
                tinymceScriptSrc="https://web.teams-broadcast.fourenergy.de/tinymce/js/tinymce/tinymce.min.js"
                onInit={(_evt, editor) => (editorRef.current = editor)}
                initialValue="<p>Die zu versendende Nachricht.</p>"
                init={{
                  language: "de",
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | blocks | " +
                    "bold italic forecolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
            </div>
            <ToastContainer />
          </div>
          <div className="mt-6 flex gap-x-6">
            <button
              onClick={() => {
                editorRef.current.execCommand(
                  "mceInsertContent",
                  false,
                  emailSign
                );
              }}
              className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
              Email {emailSign}
            </button>
            <button
              onClick={() => {
                editorRef.current.execCommand(
                  "mceInsertContent",
                  false,
                  firstnameSign
                );
              }}
              className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
              Vorname {firstnameSign}
            </button>
            <button
              onClick={() => {
                editorRef.current.execCommand(
                  "mceInsertContent",
                  false,
                  fullName
                );
              }}
              className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
              Vollständiger Name {fullName}
            </button>
            <button
              onClick={() => {
                editorRef.current.execCommand(
                  "mceInsertContent",
                  false,
                  lastName
                );
              }}
              className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
              Nachname {lastName}
            </button>
          </div>
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          onClick={() => debounce(handleSend)}
          className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
        >
          Senden
        </button>
      </div>
    </>
  );
}
