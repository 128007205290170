import "./App.css";
import "./output.css";

import Login from "./components/login.js";
import Layout from "./pages/Layout";
import QuickSend from "./pages/QuickSend.js";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { CookiesProvider, useCookies } from "react-cookie";

function App() {
  const [cookies, setCookie] = useCookies(["bdr_teams_authorization"]);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const code = urlParams.get("code");

  if (code && code !== "" && !cookies.bdr_teams_authorization) {
    // code is set, so you come from the oauth page.
    fetch(
      window.extended.TEAMS_BROADCAST_API_URL + "/api/get-token?code=" + code
    )
      .then((result) => result.json())
      .then((result) => {
        setCookie("bdr_teams_authorization", result.access_token, {
          path: "/",
        });
      });
  }

  if (cookies.bdr_teams_authorization) {
    return (
      <div className="h-full bg-gray-900">
        <CookiesProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route path="/" element={<QuickSend />} />
                <Route path="/quickSend" element={<QuickSend />} />
                <Route path="/confirm" element={<QuickSend />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </CookiesProvider>
      </div>
    );
  } else {
    return (
      <div className="h-full bg-gray-900">
        <Login></Login>
      </div>
    );
  }
}

export default App;
