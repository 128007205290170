
async function handleLogin(e) {
  e.preventDefault()
  const response = await fetch(window.extended.TEAMS_BROADCAST_API_URL + '/api/login-url')

  if (response.status === 200) {
    const data = await response.json()

    window.location.replace(data.url)
  }


}

export default function Login() {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <div>
          <button
            onClick={e => handleLogin(e)}
            type="submit"
            className="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Sign in
          </button>
        </div>
      </div>
    </div>
  )
}
